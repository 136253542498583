import * as React from "react"
import BlurredSection from "../components/BlurredSection"
import Container from "../components/Container"

const NotFoundPage = () => (
  <>
    <BlurredSection>
      <Container>The page you’re looking for doesn’t exist!</Container>
    </BlurredSection>
  </>
)

export default NotFoundPage
